<template>
  <div>
    <div @click="$emit('close')" :class="['z-40 fixed inset-0 transition-all duration-200 transform bg-gray-800', open ? 'opacity-75' : 'opacity-0 pointer-events-none']"></div>
    <div :class="['z-40 max-h-full overflow-y-auto fixed transform transition-all duration-200 origin-top -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2', classes, width, open ? 'scale-x-95 scale-y-100 opacity-100 pointer-events-auto' : 'scale-x-75 scale-y-50 opacity-0 pointer-events-none']">
      <div class="flex items-start justify-between" v-if="open">
        <slot name="title" />
        <button @click="$emit('close')" class="flex items-center justify-center p-2 -mt-1 -mr-2 text-gray-400 transition-all duration-100 transform rounded-full hover:bg-gray-800 focus:bg-gray-800 hover:text-gray-200 focus:text-gray-200 focus:outline-none" v-if="closeable">
          <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
        </button>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    closeable: {
      type: Boolean,
      default: true
    },
    open: {
      type: Boolean,
      required: true,
      default: false
    },
    classes: {
      type: String,
      default: 'px-4 py-4 rounded-md shadow-lg bg-gray-900'
    },
    width: {
      type: String,
      default: 'w-full max-w-sm'
    }
  },
}
</script>