<template>
  <div class="flex items-center leading-tight py-2px">
    <button @click="$store.dispatch('shoppinglist/checkItem', {item: item.id, old: item.bought})" :class="['p-1 mr-4 transition-all duration-100 transform border rounded-md focus:outline-none focus:ring focus:ring-primary-400 dark:focus:ring-primary-600', item.bought ? 'bg-primary-400 dark:bg-primary-600 border-primary-500 hpver:bg-primary-600 dark:hover:bg-primary-800 dark:text-white' : 'text-gray-400 hover:text-white focus:text-white border-gray-600 hover:bg-gray-700 focus:bg-gray-700']">
      <svg :class="['w-3 h-3 transition-all duration-100 transform', {'': item.bought}]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path></svg>
    </button>
    <div class="flex-1">
      <p @click="enterEdit()" :class="['py-1 text-gray-300 transition-all duration-100 transform cursor-pointer select-none', {'line-through opacity-75': item.bought}]" v-if="!edit">{{item.title}}</p>
      <input ref="edit" @keyup.enter="leaveEdit()" @keyup.esc="leaveEdit()" @blur="leaveEdit()" v-model="editTitle" type="text" class="w-full px-2 py-1 -ml-2 transition-all duration-100 transform bg-gray-700 bg-opacity-50 rounded-md focus:outline-none" v-else />
    </div>
    <button @click="$store.dispatch('shoppinglist/deleteItem', item.id)" class="p-1 ml-2 text-red-400 transition-all duration-100 transform rounded-full opacity-75 hover:bg-red-300 hover:bg-opacity-25 focus:outline-none focus:bg-red-300 focus:bg-opacity-25">
      <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
    </button>
    <div class="p-1 ml-2 text-gray-600 transition-all duration-100 transform cursor-move move-handle hover:text-gray-400">
      <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path></svg>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    edit: false,
    editTitle: ''
  }),
  props: ['id'],
  computed: {
    item: vm => vm.$store.getters['shoppinglist/item'](vm.id)
  },
  methods: {
    enterEdit() {
      this.edit = true
      setTimeout(() => this.$refs.edit.focus(), 1)
    },
    leaveEdit() {
      this.$store.dispatch('shoppinglist/updateItemTitle', { id: this.id, title: this.editTitle })
      this.edit = false
    },
  },
  created() {
    this.editTitle = this.item.title
  }
}
</script>
