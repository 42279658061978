<template>
  <div class="container max-w-md px-4 pt-4 mx-auto">
    <div @click="listDropdown = false" :class="['fixed inset-0 transform transition-all duration-200 bg-gray-400 dark:bg-gray-900', listDropdown ? 'pointer-events-auto opacity-75' : 'pointer-events-none opacity-0']"></div>
    <div class="relative w-full max-w-xs mx-auto">
      <button @click="listDropdown = !listDropdown" :class="['absolute z-20 flex items-center justify-between w-full h-10 px-2 py-1 leading-tight border border-gray-400 dark:border-gray-600 rounded-md focus:outline-none focus:ring ring-primary-400 dark:ring-primary-600 transition-all duration-100 transform', listDropdown ? 'bg-gray-200 dark:bg-gray-700 rounded-b-none' : '']">
        <template v-if="$store.getters['shoppinglist/getCurrentList'].title">{{$store.getters['shoppinglist/getCurrentList'].title}}</template>
        <span class="">
          <svg :class="['w-4 h-4 text-gray-400 transition-all duration-100 transform', {'rotate-180': listDropdown}]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
        </span>
      </button>
      <ul :class="['absolute inset-x-0 z-10 pt-1 flex flex-col transition-all duration-200 transform origin-top bg-gray-200 dark:bg-gray-700 border border-t-0 border-gray-400 dark:border-gray-600 rounded-b-md top-10', listDropdown ? 'scale-x-100 scale-y-100 opacity-100' : 'scale-x-90 scale-y-0 opacity-50']">
        <li @click="$store.commit('shoppinglist/selectList', list.id); listDropdown = false" class="flex items-center px-2 py-1 cursor-pointer hover:bg-primary-400 dark:hover:bg-primary-600" v-for="list in $store.getters['shoppinglist/lists']" :key="list.id">
          {{list.title}}
          <span class="ml-auto text-sm text-gray-600 dark:text-gray-400" v-if="!$store.getters['shoppinglist/itemsInList'](list.id)" >kein Eintrag</span>
          <span class="ml-auto text-sm text-gray-600 dark:text-gray-400" v-else-if="$store.getters['shoppinglist/itemsInList'](list.id) == 1" >{{$store.getters['shoppinglist/itemsInList'](list.id)}} Eintrag</span>
          <span class="ml-auto text-sm text-gray-600 dark:text-gray-400" v-else>{{$store.getters['shoppinglist/itemsInList'](list.id)}} Einträge</span>
        </li>
        <li @click="newListModal = true; $refs.newList.focus(); listDropdown = false" class="flex items-center px-2 py-1 text-gray-600 cursor-pointer dark:text-gray-400 hover:text-gray-900 dark:hover:text-white hover:bg-primary-400 dark:hover:bg-primary-600 rounded-b-md">
          <svg class="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
          Liste hinzufügen
        </li>
      </ul>
    </div>
    <div class="mt-16">
      <div :class="['flex items-center w-full bg-gray-300 dark:bg-gray-700 bg-opacity-50 rounded-md shadow-inner transform transition-all duration-100', focusInput || newItem ? 'ring ring-primary-400 dark:ring-primary-600' : '']">
        <div class="p-2 border border-transparent">
          <svg class="w-4 h-4 text-gray-600 dark:text-gray-200" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
        </div>
        <input v-model="newItem" @keyup.enter="createItem()" type="text" @focus="focusInput = true" @blur="focusInput = false" class="flex-1 placeholder-gray-600 bg-transparent dark:placeholder-gray-400 dark:text-white focus:outline-none" placeholder="Neuen Eintrag hinzufügen" >
      </div>
    </div>
    <div class="flex flex-col mt-4 space-y-2">
      <draggable v-model="itemsCopy" :animation="200" ghost-class="move" group="items" handle=".move-handle" class="w-full" @start="checkMove">
        <single-item v-for="item in items.sort((a, b) => b.index - a.index)" :key="item.id" :id="item.id" />
      </draggable>
    </div>
    
<!-- 


 -->


    <div class="flex justify-center w-full mt-8" v-if="$store.getters['shoppinglist/currentList'] == 1 && checkedItems.length">
      <button @click="moveModal = true" class="flex items-center justify-center px-4 py-1 text-sm text-center transition-all duration-100 transform border border-opacity-50 rounded-md text-primary-400 border-primary-400 hover:bg-primary-400 hover:text-white focus:bg-primary-400 focus:outline-none focus:text-white">
        Einkäufe zum Vorrat
        <svg class="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
        <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01"></path></svg>
      </button>
    </div>
    <div class="flex justify-center w-full mt-8" v-if="$store.getters['shoppinglist/currentList'] != 1">
      <button @click="deleteModal = true" class="px-4 py-1 text-sm text-center text-red-400 transition-all duration-100 transform border border-red-400 border-opacity-50 rounded-md hover:bg-red-400 hover:text-white focus:bg-red-400 focus:outline-none focus:text-white">
        Liste löschen
      </button>
    </div>

    <modal :open="newListModal" @close="newListModal = false" :closeable="false">
      <input v-model="newList" ref="newList" @keyup.enter="createList()" @keyup.esc="newListModal = false; newList = null" @blur="newListModal = false" type="text" class="w-full text-white placeholder-gray-400 bg-transparent focus:outline-none" placeholder="Neue Liste erstellen" >      
    </modal>

    <modal :open="deleteModal" @close="deleteModal = false" :closeable="false">
      <p class="mb-4 text-gray-400">Möchtest du diese Liste wirklich löschen?</p>
      <div class="flex items-center justify-end mt-8">
        <button @click="deleteModal = false" class="flex-1 px-4 py-2 mr-4 text-sm bg-gray-700 rounded-md sm:flex-initial focus:outline-none">Abbrechen</button>
        <button @click="deleteList()" class="flex-1 px-4 py-2 text-sm bg-red-600 rounded-md sm:flex-initial focus:outline-none">Ja, löschen</button>
      </div>
    </modal>

    <modal :open="moveModal" @close="moveModal = false">
      <template #title>{{checkedItems.length}} Elemente zum Vorrat hinzufügen?</template>
      <div class="mt-4 text-sm text-gray-200">
        <p class="" v-for="item in checkedItems" :key="item.id">{{item.title}}</p>
      </div>
      <div class="flex items-center justify-end mt-8">
        <button @click="moveModal = false" class="flex-1 px-4 py-2 mr-4 text-sm bg-gray-700 rounded-md sm:flex-initial focus:outline-none">Abbrechen</button>
        <button class="flex-1 px-4 py-2 text-sm rounded-md bg-primary-600 sm:flex-initial focus:outline-none">Verschieben</button>
      </div>
    </modal>
  </div>
</template>

<script>
import singleItem from '../components/shoppinglist/single-item'
import modal from '../components/modal'
import draggable from 'vuedraggable'

export default {
  components: { singleItem, modal, draggable },
  data: () => ({
    listDropdown: false,
    focusInput: false,
    newItem: null,
    newListModal: false,
    newList: null,
    deleteModal: false,
    moveModal: false
  }),
  computed: {
    items: vm => vm.$store.getters['shoppinglist/items'],
    itemsCopy: {
      get() {
        return this.items
      },
      set(v) {
        v.reverse().forEach(async (item, i) => {
          await this.$store.dispatch('shoppinglist/updateItemPosition', {item: item.id, index: i})
        })
      }
    },
    checkedItems: vm => vm.$store.getters['shoppinglist/items'].filter(item => item.bought)
  },
  methods: {
    async createList() {
      if(!this.newList) return
      let res = await this.$store.dispatch('shoppinglist/addList', this.newList)
      this.newList = null
      this.newListModal = false
    },
    async createItem() {
      if(!this.newItem) return
      let res = await this.$store.dispatch('shoppinglist/addItem', this.newItem)
      this.newItem = null
    },
    async deleteList() {
      if(this.$store.getters['shoppinglist/currentList'] == 1) return
      await this.$store.dispatch('shoppinglist/deleteList')
      this.deleteModal = false
    },
    checkMove() {
      if(this.items.lenght < 1) return false
    }
  }
}
</script>
